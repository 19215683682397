@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Notable&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


body {
  @apply font-sans bg-primary;

  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes scrollPlay {
  0%, 100% { transform: translateX(0%); } /* Start and end at the original position */
  50% { transform: translateX(70%); }
}

.scroll-text {
  display: block;
  width: 100%;
  white-space: wrap;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .scroll-animation-lg {
    display: inline-block;
    animation: scrollPlay 10s linear infinite;
  }
}

/* Toggle Switch */
input:checked ~ .dot {
  transform: translateX(24px); /* Adjust based on the size of your toggle */
}

.dot {
  transition: transform 0.3s ease-in-out;
}

.leaflet-container {
  z-index: 0 !important; /* Ensures map stays behind other elements */
}


